import React from "react";

const initialLoadingState = {
  IsLoading: false
};
const loadingReducer = (state, action) => {
  return {
    IsLoading: action
  };
};

export const LoadingContext = React.createContext();
export const LoadingProvider = ({ children }) => {
  const [loadingState, dispatchLoadingState] = React.useReducer(
    loadingReducer,
    initialLoadingState
  );

  return (
    <LoadingContext.Provider value={{ loadingState, dispatchLoadingState }}>
      {children}
    </LoadingContext.Provider>
  );
};
