import React from "react";

const initialEmailState = {
  emailSent: false
};
const emailReducer = (state, action) => {
  return {
    emailSent: action
  };
};

export const EmailContext = React.createContext();
export const EmailProvider = ({ children }) => {
  const [emailState, dispatchEmailState] = React.useReducer(
    emailReducer,
    initialEmailState
  );

  return (
    <EmailContext.Provider value={{ emailState, dispatchEmailState }}>
      {children}
    </EmailContext.Provider>
  );
};
