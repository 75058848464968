import React from "react";
import PropTypes from "prop-types";

const initialMeetingState = {};

const meetingReducer = (state, action) => {
  switch (action.type) {
    case "setData":
      return {
        ...state,
        ...action.data
      };
    case "setMeeting":
      return {
        ...state,
        contentfulMeeting: action.data
      };
    case "setAdvisor":
      return {
        ...state,
        contentfulAdvisor: action.data
      };
    default:
      throw new Error("Records reducer case not found.");
  }
};
export const MeetingContext = React.createContext(initialMeetingState);

export const MeetingProvider = ({ children = null }) => {
  const [meetingState, dispatchMeetingState] = React.useReducer(
    meetingReducer,
    initialMeetingState
  );

  return (
    <MeetingContext.Provider value={{ meetingState, dispatchMeetingState }}>
      {children}
    </MeetingContext.Provider>
  );
};

MeetingProvider.propTypes = {
  children: PropTypes.node
};
