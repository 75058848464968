import React from "react";
import { LoadingProvider } from "./src/context/LoadingProvider";
import { MeetingProvider } from "./src/context/MeetingProvider";
import { EmailProvider } from "./src/context/EmailProvider";
import "typeface-poppins";
export const wrapRootElement = ({ element }) => {
  return (
    <EmailProvider>
      <MeetingProvider>
        <LoadingProvider>{element}</LoadingProvider>
      </MeetingProvider>
    </EmailProvider>
  );
};
